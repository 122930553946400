import { Component, OnInit } from '@angular/core';
import { getEnvironment, setEnvironment } from 'src/app/functions/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  env: any = {};
  constructor() {
    this.env = getEnvironment();
  }

  ngOnInit() {
  }

  saveValues(env){
    setEnvironment(env);
  }

  exportFile(){
    var blob = new Blob([JSON.stringify(this.env)], {type: 'application/octet-stream'});
    saveAs(blob, "settings.json");
  }

}
