import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicesApiChangesService } from 'src/app/services';
import { detectedPerson } from 'src/app/core/detectedPerson';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import imageCompression from 'browser-image-compression';
import * as _ from "lodash";
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { parseMqtt, getMqttParsedMessage } from 'src/app/core/functions/person';
import { Subscription } from 'rxjs';
import { PulsesDemoServices } from 'src/app/services/pulsesDemoService';
@Component({
  selector: 'app-metro',
  templateUrl: './metro.component.html',
  styleUrls: ['./metro.component.scss'],
})
export class MetroComponent implements OnInit, OnDestroy {
  @ViewChild(CameraComponent) cameraComponent;
  @Input('CameraType') CameraType = 'checkin';
  @Input('StationName') StationName = 'Emirates';
  env = environment;
  waitRegistration:boolean;
  metroPerson:any;
  metroDetectedUsers = [];
  metroCurrency = 'AED';
  pinCode = [
    {value: ''},
    {value: ''},
    {value: ''},
    {value: ''},
  ];
  companyName = "";
  noSufficentFund:boolean = false;
  userUpdate:boolean;
  mqttSubscription:Subscription;
  mqttMessage:Subscription;
  constructor(
    private _mqttService: MqttService,
    private toaster: ToastrService, 
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private pulsesDemoServices: PulsesDemoServices,
    private servicesApiChangesService: ServicesApiChangesService,
  ) {
    // this.servicesApiChangesService.changeServer('custom');

    // this.userUpdate = !!((this.router.url).split('/')[1].split('?')[1]);

    this.activatedRoute.queryParams.subscribe(_query=>{
      if((this.router.url).split('/').slice(-1)[0]=='violations'){
        clearInterval(this.videoCaptureInteval);
      }
    })
    this.companyName = (this.router.url).split('/').splice(-1)[0];
    if((this.router.url).split('/')[1]=='metro'){
      this.companyName = 'rta';
    }
    
    let contValue:number = 0;
    let metroDetectedUsers = [];
    let _metroDetectedUsers;

    this.mqttMessage = getMqttParsedMessage.subscribe(parsedMessage=>{
      console.log(parsedMessage);
      let person:any = parsedMessage;

      if(this.waitRegistration){return}

      if(person.newCreated){
        person.newRegistered = true;
        person.name = '';
        this.userUpdate = true;
      }
      
      if(!person.newRegistered){
        metroDetectedUsers = _.uniqBy(_metroDetectedUsers, 'personID')
        metroDetectedUsers.unshift(person);
      }else{
        if(contValue>3){
          return;
        }
      }

      contValue++;
      if(contValue == 3){
        if(metroDetectedUsers.length==0){
          this.metroPerson = person;
          this.metroPerson.userData = person.userData;
          this.waitRegistration = true;
        }else{
          this.metroPerson = metroDetectedUsers[0];
          this.metroPerson.userData = metroDetectedUsers[0].userData;
        }
      }
    });

    // if(environment.receiveFacesOnly){
    //   this.mqttSubscription = this._mqttService.observe('/pulses_demo/person').subscribe((message: IMqttMessage) => {
    //     let _message:any = message.payload.toString();
    //     parseMqtt(_message);
    //   });
    // }
  }

  editMetroPerson(person){
    let _person = JSON.parse(JSON.stringify(person));
    _person.userData = (typeof _person.userData == 'object')?JSON.stringify(_person.userData):_person.userData;
    return this.pulsesDemoServices.editPerson(_person);
  }

  videoCaptureInteval;
  startCapturing(){
    this.videoCaptureInteval = setInterval(() => {
      if(!this.metroPerson||!this.metroPerson.newRegistered){
        this.cameraComponent.genBase64Main().then( base64=>{
          // original code imageCompression.getFilefromDataUrl(base64, {maxIteration: 1,})
          imageCompression.getFilefromDataUrl(base64, 'Fille.jpg')
          .then((compressedFile)=>{
            const file = new File([compressedFile], "File.jpg", {type: 'image/png'});
            return this.detectAndIdentify(file, "checkEquality");
          });
        })
        .catch( e=>{console.error(e)} );
      }
    }, environment.AppConfig.captureInterval);
  }

  detectAndIdentify(file,obj?){
    this.pulsesDemoServices.detectAndIdentifyPerson(file, true, false);
  }

  topUp:false;
  chargeMetro(metroPerson, balance){
    this.noSufficentFund = false;
    if(typeof this.metroPerson.userData == 'string'){
      this.metroPerson.userData = JSON.parse(this.metroPerson.userData);  
    }
    if(!this.metroPerson.userData.balance){
      this.metroPerson.userData.balance = 0;
    }
    let _balance:any = (this.metroPerson.userData.balance + '').split(' ')[0];
    _balance = Number(_balance);
    this.metroPerson.userData.balance = _balance + Number(balance);
    
    this.topUp = false;
    this.editMetroPerson(metroPerson).subscribe();
  }

  duration:any = '4:30';
  pinResentTime;
  pinResentTimeFn(){
    this.pinResentTime = moment().add(5, 'minutes');
    let counter;
    this.pinResendTimeInterval = setInterval(()=>{
      counter = 1;
      let time:any = this.duration.split(':');
      time[1] = time[1] - counter;
      if(time[1]==0){
        if(time[0]==0){
          clearInterval(this.pinResendTimeInterval);
          return false;
        }else{
          time[0] = time[0] - counter;
        }
      }
      if(time[1]<0){
        time[1] = 59;
      }
      this.duration = '';
      this.duration = this.duration + time[0] + ':';
      this.duration = this.duration + time[1] + '';
    }, 1000)
  }
  pinResendTimeInterval;

  _pinValue = "1234";
  metroConfirm:boolean = false;
  paymentActive:boolean = false;
  pinChangeCheck(event, i){
    if(isNaN(event)||!event){
      return;
    }
    this.pinCode[i]['value'] = event;
    let targetElm = document.getElementById(`pin${i+1}`);
    if(targetElm){
      targetElm.focus();
    }
    let pinNum = '';
    this.pinCode.map(res=>{
      pinNum = pinNum + res.value;
    });

    setTimeout(() => {
      if((pinNum+'') == this._pinValue){
        this.metroPerson.userData.balance = 10;
        const _metroPerson = JSON.parse(JSON.stringify(this.metroPerson));
        _metroPerson.userData = JSON.stringify(_metroPerson.userData);
        // original code imageCompression.getFilefromDataUrl(_metroPerson.FaceImageBase64)
        imageCompression.getFilefromDataUrl(_metroPerson.FaceImageBase64, 'File.jpg')
        .then((compressedFile) => {
          _metroPerson.images = [];
          _metroPerson.images.push(compressedFile);
          this.toaster.success("Succed!");
          
          const req = this.userUpdate?this.editMetroPerson(_metroPerson):this.pulsesDemoServices.createNewPerson(_metroPerson);

          req.subscribe(res=>{
            setTimeout(() => {
              this.metroConfirm = false;
              this.metroPerson.newRegistered = false;            
              this.paymentActive = true;
              this.waitRegistration = false;
              this.toaster.clear();
              this.toaster.success("Succed!");
            }, 100);
          })
        })
      }else{
        if((pinNum+'').length==this._pinValue.length){
          this.toaster.error("PIN is incorrect")
        }
      }

    }, 100);
  }

  ngOnInit() {
    this.startCapturing();
  }

  ngOnDestroy(){
    clearInterval(this.videoCaptureInteval);
    this.mqttMessage.unsubscribe();
    this.mqttMessage.unsubscribe();
  }
}
