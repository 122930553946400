import { Injectable } from '@angular/core';
import * as _ from "lodash";
import * as moment from 'moment';
import { PulsesDemoServices } from '../services/pulsesDemoService';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class VisitorCountsService {

  groupedUsers = [];
  groupedUsers$ = new Subject();

  waitTime: number = 1; // in minutes
  visitorsCount: number = 0;
  detectedUsers = [];

  dataTableUpdates$ = new Subject();

  constructor(private pulsesDemoServices: PulsesDemoServices) {
    setInterval(() => {
      // this.detectedUsers = localStorage.getItem('detectedUsers')?JSON.parse(localStorage.getItem('detectedUsers')):[];
      // if(this.detectedUsers.length==0){return;}
      // if(moment().diff(moment(this.detectedUsers.slice(-1)[0].time), 'minutes')>=this.waitTime){
      //   this.detectedUsers = [...this.detectedUsers,{
      //     time: moment().startOf('minute'),
      //     detectedUsers: [],
      //     detectedUsersCount: 0,
      //   }];
      //   this.updateLocalStorage('detectedUsers', JSON.stringify(this.detectedUsers));
      //   this.dataTableUpdates$.next(this.detectedUsers);
      // }
      this._countUser();
    }, 60000);
  }

  private updateLocalStorage(name: string, value: string) {
    localStorage.setItem(name, value);
    console.log("All the detected Users", this.getCountAllUsers());
  }

  private checkDiffMinute(a, b) {
    const momentTime = (t) => moment(t);
    return momentTime(a).diff(momentTime(b), 'minutes') >= this.waitTime;
  }

  incrementVisitorsCount(user, allDetectedUsers) {
    this.detectedUsers = localStorage.getItem('detectedUsers') ? JSON.parse(localStorage.getItem('detectedUsers')) : [];

    const createNewObjRow = () => {
      this.detectedUsers = [...this.detectedUsers, {
        time: moment().startOf('minute'),
        detectedUsers: [user.name],
        detectedUsersCount: 1,
      }];
      this.updateLocalStorage('detectedUsers', JSON.stringify(this.detectedUsers));
      this.groupUser(allDetectedUsers, this.detectedUsers[0].detectedUsers);
      return;
    }

    if (this.detectedUsers.length == 0) {
      createNewObjRow();
    }

    let latestData = this.detectedUsers.slice(-1)[0];
    const lastTime = latestData.time;

    if (this.checkDiffMinute(user.DetectionDateTime, lastTime)) {
      createNewObjRow();
    } else {
      const userExistInRow = _.includes(latestData.detectedUsers, user.name);
      if (!userExistInRow) {
        latestData.detectedUsers = [...latestData.detectedUsers, ...user.name];
        latestData.detectedUsersCount = latestData.detectedUsersCount + 1;

        this.groupUser(allDetectedUsers, latestData.detectedUsers);
      }
      this.updateLocalStorage('detectedUsers', JSON.stringify(this.detectedUsers));
    }

  }

  groupUser(allDetectedUsers, detectedUsers) {

    if (this.detectedUsers.length > 1) {
      const lenth = this.detectedUsers.length;
      const beforeLastArr = this.detectedUsers[lenth - 2].detectedUsers;
      const lastArr = this.detectedUsers[lenth - 1].detectedUsers;
      let detectUsers = 0;
      lastArr.map(name => {
        const index = _.findIndex(beforeLastArr, e => e == name);
        if (index != -1) {
          detectUsers++;
        }
      });
      if (beforeLastArr == detectUsers) {
        return false;
      }
    }

    let data = [];
    detectedUsers.map(name => {
      // const index = _.findIndex(allDetectedUsers, e=>e.name==name);
      // if(index!==-1){
      // const _user = allDetectedUsers[index];
      data = allDetectedUsers.map(_user => {
        return {
          name: _user.name,
          datetime: moment(_user.DetectionDateTime).format('YYYY-MM-DD HH:mm:ss.0'),
          image: _user.FaceImageBase64.split('base64,')[1],
        }
      });
      // data = [...data, {
      //   name: _user.name,
      //   datetime: moment(_user.DetectionDateTime).format('YYYY-MM-DD HH:mm:ss.0'),
      //   image: _user.FaceImageBase64.split('base64,')[1],
      // }]
      // }
    });

    this.pulsesDemoServices.grouping(data).subscribe((a: any) => {
      //debugger;
      if (Object.keys(a).length == 0) { return }
      this.groupedUsers = [];

      let b = a.groups;
      let c = [];
      Object.keys(b).map(e => {
        if (!c[b[e]]) {
          c[b[e]] = [e];
          return;
        }
        c[b[e]].push(e);
      });
      if (c.length > 0) {
        this.groupedUsers$.next(c);
      }
    });
  }

  getCountAllUsers() {
    this.visitorsCount = 0;
    this.detectedUsers.map(t => this.visitorsCount = this.visitorsCount + t.detectedUsersCount);
    return this.visitorsCount;
  }


  _groupUser(users, ) {
    const data = users.map(_user => {
      return {
        name: _user.name,
        datetime: moment(_user.DetectionDateTime).format('YYYY-MM-DD HH:mm:ss.0'),
        image: _user.FaceImageBase64.split('base64,')[1],
      }
    });
    return this.pulsesDemoServices.grouping(data).pipe(
      map((a: any) => {
        if (typeof (a) === 'string') {
          a = JSON.parse(a);
        }


        if (Object.keys(a).length == 0) { return [] }
        this.groupedUsers = [];

        let b = a.results.groups;
        let c = [];
        let undetected = [];
        Object.keys(b).map(name => {
          const groupVal = b[name];
          if (c[groupVal]) {
            if (!c[groupVal][name]) {
              c[groupVal].push(name);
            }
          } else {
            c[groupVal] = [name];
          }
        });
        // Object.keys(b).map(e => {
        //   if (!c[b[e]]) { c[b[e]] = [e]; return; }
        //   c[b[e]].push(e);
        // });

        //Add the Undetected Faces 
        if (a.results.undetected_face) {
           a.results.undetected_face.map(u => undetected.push([u]));
        }

        return {
          groupped: c,
          undetected: undetected
        };
      })
    );
  }

  _countUser(detectedUsers?, allUsers?) {
    this.detectedUsers = localStorage.getItem('detectedUsers') ? JSON.parse(localStorage.getItem('detectedUsers')) : [];
    let index = _.findIndex(this.detectedUsers, e => moment(e.time).format("dddd, MMMM Do YYYY, h:mm:ss a") == moment(moment().startOf('minute')).format("dddd, MMMM Do YYYY, h:mm:ss a"));
    if (index == -1) {
      this.detectedUsers.push({
        time: moment().startOf('minute'),
        detectedUsers: detectedUsers ? detectedUsers : [],
        detectedUsersCount: detectedUsers ? detectedUsers.length : 0,
      });
    } else {
      this.detectedUsers[index] = {
        time: moment().startOf('minute'),
        detectedUsers: detectedUsers ? detectedUsers : [],
        detectedUsersCount: detectedUsers ? detectedUsers.length : 0,
      }
    }
    this.updateLocalStorage('detectedUsers', JSON.stringify(this.detectedUsers));
    this.dataTableUpdates$.next(this.detectedUsers);

    // Hapinness
    let _allUsers = localStorage.getItem('allUsers') ? JSON.parse(localStorage.getItem('allUsers')) : {};

    if (allUsers != null)
      allUsers.map((user) => {
        if (!_allUsers[user.emotion]) { _allUsers[user.emotion] = {} }
        if (_allUsers[user.emotion][user.name]) {
          _allUsers[user.emotion][user.name]++;
        } else {
          _allUsers[user.emotion][user.name] = 0;
        }
      });
    localStorage.setItem('allUsers', JSON.stringify(_allUsers));
  }
}
