import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import imageCompression from 'browser-image-compression';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/first';
import { parseMqtt } from '../core/functions/person';
import * as moment from 'moment';
import { getEnvironment } from '../functions/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PulsesDemoServices implements IFaceAPIServices {
    environment:any;
    _analyzer:any;
    constructor(
        private http: HttpClient
    ){
        this.environment = getEnvironment();
    }

    private createGroup(){
        const formData = new FormData();
        return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/NewPersonGroup`, formData);
    }

    publishToNodes(topic, message)
    {
        const formData = new FormData();
        formData.append("message", message);
        formData.append("topic", topic);
        return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/publishToNodes`, formData).subscribe(_message=>{
            // should we use it or not?
          //  parseMqtt(_message);
        });

    }
    detectAndIdentifyPerson(file, forceCall=false, publishToMQTT=true, analyzer?){
        //DO NOT EVEN CALL THE API if the ReceiveFacesOnly = true 
        if(this.environment.receiveFacesOnly && !forceCall) return "[]"; 
        this._analyzer = analyzer || this.environment.AppConfig.analyzer;
        const formData = new FormData();
        // debugger;
        formData.append("publishToMQTT", publishToMQTT+'');
        formData.append("Image", file);
        formData.append("analyzer", this._analyzer);
        //Azure Parameters
        formData.append("AzureConfidenceThreshold", JSON.stringify(this.environment.AppConfig.confidenceThreshold));
        //Pulses Parameters
        formData.append("ImageIsFaceOnly", JSON.stringify(this.environment.AppConfig.ImageIsFaceOnly));
        formData.append("SimilarityMargin", JSON.stringify(this.environment.AppConfig.SimilarityMargin));
        formData.append("AddPersonIfDoesntExist", JSON.stringify(this.environment.AppConfig.AddPersonIfDoesntExist));
        formData.append("ReturnDetectedFace", JSON.stringify(this.environment.AppConfig.ReturnDetectedFace));
        formData.append("DetectMask", JSON.stringify(this.environment.AppConfig.DetectMask));
        
        return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/DetectAndIdentify`, formData).subscribe(_message=>{
            // it 
            parseMqtt(_message);
        });
    }

    createNewPerson(person){
        const formData = new FormData();
        formData.append("Name", person.name);
        formData.append("UserData", person.userData);
        person.images.map(_img => {
            formData.append("Images", _img);
        });
        return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/NewPerson/`, formData);
    }

    editPerson(person, url?){
        const formData = new FormData();
        if(this._analyzer =='pulses'){
            formData.append("oldFaceId", person.faceId);
            formData.append("newFaceId", person.name);
            return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/updatelabel`, formData);
        }else{
            formData.append("Name", person.name);
            formData.append("UserData", person.userData);
            formData.append("UserID", person.personId);
            return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/EditPerson`, formData);
        }
    }

    grouping(data){
        return this.http.post(`${this.environment.AppConfig.CustomAPI}Face/grouping`, {data: data});
    }

    deleteFaceFromCache(faceId): Observable<any> {
        return this.http.get(`${this.environment.AppConfig.CustomAPI}Face/deleteFaceFromCache?faceId=${faceId}`);
    }
}
