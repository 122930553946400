import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ServicesApiChangesService } from 'src/app/services';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import { environment } from 'src/environments/environment';
import imageCompression from 'browser-image-compression';
import { detectedPerson } from 'src/app/core/detectedPerson';
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { HttpClient } from '@angular/common/http';
import { getEmotion } from 'src/app/core/functions';
import { getMqttParsedMessage, parseMqtt } from 'src/app/core/functions/person';
import { PulsesDemoServices } from 'src/app/services/pulsesDemoService';

@Component({
  selector: 'app-violaters',
  templateUrl: './violaters.component.html',
  styleUrls: ['./violaters.component.scss']
})
export class ViolatersComponent implements OnInit, OnDestroy {
  @ViewChild(CameraComponent) cameraComponent;

  transactions = [];
  detectedPersons = [];
  isTransactions:boolean;
  
  selectedViolation = 'VIP';
  violationTypes = [
    {label:'VIP cart', value: 'VIP'},
    {label:'Ladies cart', value: 'Women'},
    {label:'Balance', value: 'Balance'},
  ];

  selectedSortType = 'Date';
  sortTypeTypes = [
    {label:'Date', value: 'Date'},
    {label:'Amount', value: 'Amount'},
    {label:'Info', value: 'Info'},
  ]
  private checkInSubscription: Subscription;
  private checkOutSubscription: Subscription;
  private violatorsSubscription: Subscription;
  public message: string;
  _detectedPerson = [];

  mqttMessage: Subscription;
  constructor(
    private _mqttService: MqttService,
    private toaster:ToastrService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    // private servicesApiChangesService: ServicesApiChangesService,
    private pulsesDemoServices: PulsesDemoServices,
  ) {
    // this.servicesApiChangesService.changeServer('custom');
    
    this.mqttMessage = getMqttParsedMessage.subscribe(parsedMessage=>{
      if((this.router.url).split('/').slice(-1)[0]=='transactions'){
        !environment.receiveFacesOnly?
        this.mainMonitor(parsedMessage):
        this.displayInTable(parsedMessage);
      }
      else{
        this['violation' + this.selectedViolation](parsedMessage)
      }
    });
    
    
    if(environment.receiveFacesOnly){
      //For violators 
      this.violatorsSubscription = this._mqttService.observe('/pulses_demo/person').subscribe((message: IMqttMessage) => {
        let _message:any = message.payload.toString();
        parseMqtt(_message);
      });

      this.checkInSubscription = this._mqttService.observe('/pulses_demo/people/checkin').subscribe((message: IMqttMessage) => {
        let _message = message.payload.toString();
        // parseMqtt(this.message);
        //debugger;
        this.displayInTable(JSON.parse(_message)); // directly publish in table that's it data is correct
      });

      this.checkInSubscription = this._mqttService.observe('/pulses_demo/people/checkout').subscribe((message: IMqttMessage) => {
        let _message = message.payload.toString();
        // parseMqtt(_message);
        //debugger;
        this.displayInTable(JSON.parse(_message));
      });
    }

    this.activatedRoute.queryParams.subscribe(_query=>{
      if((this.router.url).split('/').slice(-1)[0]=='transactions'){
        this.isTransactions = true;
      }else{
        this.isTransactions = false;
      }
    });
    
  }

  videoCaptureInteval;
  startCapturing(){
    // if(environment.receiveFacesOnly){return}
    
    
    this.videoCaptureInteval = setInterval(() => {
      this.cameraComponent.genBase64Main().then( base64=>{
        // original code imageCompression.getFilefromDataUrl(base64, {maxIteration: 1,})
        imageCompression.getFilefromDataUrl(base64, 'File.jpg')
        .then((compressedFile)=>{
          const file = new File([compressedFile], "File.jpg", {type: 'image/png'});
          return this.detectAndIdentify(file);
        });
      })
      .catch( e=>{console.error(e)} );
    }, environment.AppConfig.captureInterval);
  }

  detectAndIdentify(file){
    
    this.pulsesDemoServices.detectAndIdentifyPerson(file, false, 
      !((this.router.url).split('/').slice(-1)[0]=='transactions'));
      //Send MQTT Message if the tag is violation
  }

  ngOnInit() {
    this.startCapturing();
  }

  mainMonitor(person){
    console.log (person.userData.status);
    //Detect a person 
    //Identify a person 
    //If person identified 
    //Check camera type 
    //this.checkinPerson or this.checkoutPerson
    //If person is NOT identified (violator)
    if(environment.metro.type.toLowerCase()=='checkin'){
      //debugger;
      console.log (person.userData.status);
      if(person.userData.checkedIn){return}
      this.checkinPerson(person);
      this.publishToMqtt('/pulses_demo/people/checkin', person); 

    }
    if(environment.metro.type.toLowerCase()=='checkout'){
//debugger;
    if(person.userData.status !== 'checkin'){return}
      this.checkoutPerson(person);
      this.publishToMqtt('/pulses_demo/people/checkout', person);
    }
  }
  publishToMqtt(topic, message){
    //Publish here
    //TODO: Add Retained 
    // debugger;
    //Call Abdulrahman service here 
    // this.pulsesDemoServices.pub
  //  this._mqttService.unsafePublish(topic, message,{qos: 1, retain: false});
    this.pulsesDemoServices.publishToNodes(topic,JSON.stringify(message));
  }
  checkinPerson(person){
    //If person.Status = 'Checkedin' return;
    //Check the person in (Update the status)
    //Save Checkin Station 
    //Save Checkin Time 
    //DISPLAY ON GRID 
    
    person.userData.checkOutStation = '';
    person.userData.checkOutTime = '';
    this.displayInTable(person);
    
    if(person.userData.checkedIn){return}
    person.userData.status = 'checkin';
    person.userData.checkInStation = environment.metro.station;
    person.userData.checkInDateTime = moment().format('MM/DD/YYYY hh:mm:ss');
    
    //person.userData.balance = Number(person.userData.balance || 0) - environment.metroFee;
    person.personID = person.personId;
    this.updatePerson(person, true);
  }
  checkoutPerson(person){
    //If person.Status != 'Checkedin' return;
    if(person.userData.status !== 'checkin'){return}
    //Check the person OUT (Update the status)
    //Save CheckOUT Station 
    //Save CheckOUT Time 
    //ChARGE 3 DIRHAMS AND UPDATE BALANCE 
    //DISPLAY ON GRID 

    person.userData.checkedIn = false;
    person.userData.status = 'checkout';
    person.userData.checkOutStation = environment.metro.station;
    person.userData.checkOutTime = moment().format('MM/DD/YYYY hh:mm:ss');
    person.userData.balance = Number(person.userData.balance) - environment.metroFee;
    person.personID = person.personId;
    this.updatePerson(person);
    this.displayInTable(person);
  }
  displayInTable(person){

    if(person.userData.balance&&(person.userData.balance<0)){
      person.userData.status == 'checkout';
    }

    if(person.userData.status == 'checkin'){
      person.userData.amount = '';
    }else{
      person.userData.amount = '3 AED';
    }

    let transactions = _.cloneDeep(this.transactions);
    this.transactions = [];

    transactions.map(n=>{
      if(n.personId !== person.personId){
        this.transactions.push(n);
      }
    });
    this.transactions = [
      ...this.transactions,
      {
        ...person,
        faceOfThePerson: {
          img: person.FaceImageBase64,
          name: person.name,
        },
      }
    ];
    this.transactions = [...this.transactions];
  }
  updatePerson(person, checkIn?){
    let _person = JSON.parse(JSON.stringify(person));
    checkIn?_person.userData.checkedIn = checkIn:'';
    _person.userData = JSON.stringify(_person.userData);
    this.pulsesDemoServices.editPerson(_person).subscribe();
  }
  // VIOLATIONS

  clearResults(){this.detectedPersons=[]}
  // Check Violations for VIP
  violationVIP(person){
    const isVIP = person.userData.vip;
    if(isVIP){return}
    
    person.violationType = "Regular Passenger in Gold cart"
    this.detectedPersons.unshift(person)
    this.detectedPersons = _.uniqBy(this.detectedPersons, 'personID');
  }

  // Check Violations for Women
  violationWomen(person){
    if(person.gender!=='man'){return}

    person.violationType = "Man in ladies cart"
    this.detectedPersons.unshift(person);
    this.detectedPersons = _.uniqBy(this.detectedPersons, 'personID');
    
  }

  // Check Violations for Balance
  violationBalance(person){
    const balance = Number(person.userData.balance);
    if(balance > 0){return}
    
    person.violationType = "Insufficient Balance"
    this.detectedPersons.unshift(person)
    this.detectedPersons = _.uniqBy(this.detectedPersons, 'personID');
  }


  ngOnDestroy(){
    clearInterval(this.videoCaptureInteval);
    this.checkInSubscription.unsubscribe();
    this.checkOutSubscription.unsubscribe();
    this.violatorsSubscription.unsubscribe();
    this.mqttMessage.unsubscribe();
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, {qos: 1, retain: true});
  }

}

