import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import imageCompression from 'browser-image-compression';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/first';
import { ImageCropperService } from '../assets/cropper';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CustomAPIServices implements IFaceAPIServices {
    constructor(
        private http: HttpClient
    ){}

    createGroup(){
        const formData = new FormData();
        formData.append("GroupID", environment.GROUP_ID);
        return this.http.post(`${environment.AppConfig.CustomAPI}Face/NewPersonGroup`, formData);
    }

    detectAndIdentifyPerson(file, createPersonIfNotFound = false){
        const formData = new FormData();
        formData.append("GroupID", environment.GROUP_ID);
        formData.append("Image", file);
        return this.http.post(`${environment.AppConfig.CustomAPI}Face/DetectAndIdentify`, formData);
    }

    createNewPerson(person){
        const formData = new FormData();
        formData.append("Name", person.name);
        formData.append("UserData", person.userData);
        formData.append("GroupID", environment.GROUP_ID);

        return this.http.post(`${environment.AppConfig.CustomAPI}Face/NewPerson/`, formData);
    }

    editPerson(person){
        const formData = new FormData();
        formData.append("Name", person.name);
        formData.append("UserData", person.userData);
        formData.append("UserID", person.personID);
        formData.append("GroupID", environment.GROUP_ID);

        return this.http.post(`${environment.AppConfig.CustomAPI}Face/EditPerson`, formData);
    };
}
