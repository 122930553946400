import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'happiness-chart',
  templateUrl: './happiness-chart.component.html',
  styleUrls: ['./happiness-chart.component.scss']
})
export class HappinessChartComponent implements OnInit {

  charts = [];
  constructor() {
    let allUsers:any = localStorage.getItem('allUsers')?JSON.parse(localStorage.getItem('allUsers')):{};
    if(Object.keys(allUsers).length !== 0){
      let totalUsers = 0;
      Object.keys(allUsers).map(_key=>{
        totalUsers = totalUsers + Object.keys(allUsers[_key]).length;
      });
      Object.keys(allUsers).map(_key=>{
        this.charts.push({
          label: _key,
          value: ((100*Object.keys(allUsers[_key]).length)/totalUsers).toFixed(1),
        });
      });
    }
  }

  ngOnInit() {
  }

}
