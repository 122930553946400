import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { getEnvironment } from 'src/app/functions/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {

  environment:any;
  constructor(
    private http: HttpClient
  ) {
    this.environment = getEnvironment();
  }

  crop(img,x,y,w,h){
    return this.postImages(
      `areawidth=${w}&areaheight=${h}&top=${y}&left=${x}`,
      img
    );
  }

  public postImages(_params, _data) {
    return this.http.post(`${this.environment.AppConfig.CropURL}extract?${_params}`, _data, {
      responseType: 'blob'
    });
  }

}