import { getEmotion } from '.';
import { Subject } from 'rxjs';

export const getMqttParsedMessage = new Subject();

const isPersonKnown = (person:any)=>{
  // person.Person.map(e=>console.log(e.name));
  if(person.Person == undefined ) return false;
  let _length = person.Person.length;
  let _person = person.Person.filter(e=>e.name.match('unknown')).length;
  
  return _length > _person;
}
export let parseMqtt = (message:any)=>{
  if(message){
    let persons = JSON.parse(message);
    if(persons&&persons.length>0){
      
      persons.map(_person=>{
        if(_person.Person && _person.Person.length > 0){
          let knownPerson = isPersonKnown(_person);
          let _detectedPerson = _person.Person[0];
          // _person.Person.map(_detectedPerson=>{
            if(_detectedPerson.name.match('unknown') && knownPerson){return}
            //Skip the unknown people in the person list 

            _detectedPerson.newCreated = false;
            if(_detectedPerson.name.match('unknown') ){
              _detectedPerson.newCreated = true;
            }


            _detectedPerson.faceId = _person.DetectedFace.faceId;
            _detectedPerson.gender = (_person.DetectedFace.faceAttributes.gender=='male'||_person.DetectedFace.faceAttributes.gender=='man')?'man':'woman';
            _detectedPerson.age = _person.DetectedFace.faceAttributes.age;
            _detectedPerson.emotion = getEmotion(_person.DetectedFace.faceAttributes.emotion);
            _detectedPerson.hasMask = _person.DetectedFace.faceAttributes.hasMask;
            _detectedPerson.DetectionDateTime = new Date();
            _detectedPerson.FaceImageBase64 = 'data:image/png;base64,' + _person.ImgBase64;
            if(_detectedPerson.userData){
              _detectedPerson.userData = JSON.parse(_detectedPerson.userData);
            }else{_detectedPerson.userData = {}}
            getMqttParsedMessage.next(_detectedPerson);
          // });
        }
      });
    }
  }
}