// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//const _HOST = "beta-demo-api.pulses.ai";
const _HOST = "localhost";
// const _HOST = "192.168.43.2";
const API = `https://${_HOST}:11000/`;
export const environment = {
  bgShow: true,
  bgBefore: 'https://beta-app.pulses.ai/assets/imgs/registration-managment/dhofar-before.png',
  bgAfter: 'https://beta-app.pulses.ai/assets/imgs/registration-managment/background-after.png',
  production: false,
  API,
  API_CUSTOM: `http://sandbox.pulses.ai:8888/`, //USED TO UPDATE LABELS , Add Person, grouping 
  GROUP_ID: "another-group",
  saveCaptureImages: false,
  partnerLogoURL: './assets/partners/in5-logo.png',
  title: "Pulses In Action!",
  adsIntervalSecond: 5,
  adsImageInterval: 5000,
  workingTime: {
    starts: 0,
    ends: 24
  },
  metroCaptureInterval: 5000,
  metroFee: 3,
  metroChrageSecond: 5,
  metroCurrency: 'AED',
  metro: {
    station: 'Dubai mall',
    type: 'checkout',
  },
  receiveFacesOnly: true,
  AppConfig: {
    host: 'sandbox.pulses.ai' ,//`${_HOST}`, MQTT Host
    port: 15674, //MQTT Port
    analyzer: 'pulses',
    API,
    AZURE_API:`http://${_HOST}:5000/`,
    CropURL: `http://${_HOST}:9000/`,
    CustomAPI: `${API}api/`,
    title: 'Pulses-Demo',
    confidenceThreshold: 0.47,
    captureInterval: 2000,
    showAge: true,
    showGender: true,
    showEmotions: true,
    showDetectionTime: true,
    showChart: false,
    ImageIsFaceOnly: false,
    SimilarityMargin: 1.1,
    AddPersonIfDoesntExist: true,
    ReturnDetectedFace: true,
    DetectMask: true,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
