import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AzureAPIServices } from './azure';
//import { PulsesAPIServices } from './pulses';
import { CustomAPIServices } from './custom';

@Injectable({
  providedIn: 'root'
})
export class ServicesApiChangesService {

  constructor(private toastr: ToastrService,
    private azureAPIServices: AzureAPIServices,
    //private pulsesAPIServices: PulsesAPIServices,
    private customAPIServices: CustomAPIServices,
  ){}

  public defaultServer: string = "azure";
  
  public APIServices:any = this.azureAPIServices;
  public changeServer(server: string){
    // if(server == 'azure'){
    //   this.defaultServer = server;
    //   this.APIServices = this.azureAPIServices;
    //   this.azureAPIServices.getGroupPersons().subscribe();
    //   this.toastr.success(`Service switched to ${server}`)
    // }else if(server == 'pulses'){
    //   this.defaultServer = server;
    //   this.APIServices = this.pulsesAPIServices;
    //   this.toastr.success(`Service switched to ${server}`)
    // }else if(server == 'custom'){
    //   this.defaultServer = server;
    //   this.APIServices = this.customAPIServices;
    //   this.customAPIServices.createGroup().subscribe();
    //   this.toastr.success(`Service switched to ${server}`)
    // }else{
    //   this.toastr.error(`Service not switched to ${server}`)
    // }
  }
  
  
}
