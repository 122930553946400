import { Component, AfterContentInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { getEnvironment } from './functions/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit {
  env:any;

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
  constructor(private titleService: Title){
    this.env = getEnvironment();

    this.setTitle(this.env.title);

  }

  animationView = true;
  ngAfterContentInit(){
    setTimeout(() => {
      this.animationView = false;
    }, 800);
  }
}
