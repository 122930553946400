import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { AccordionModule } from 'primeng/accordion';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';

// DropdownModule
import { TooltipModule } from 'primeng/tooltip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { WebcamModule } from 'ngx-webcam';

import { HttpClientModule } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ToastrModule } from 'ngx-toastr';


import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
// COMPONENTS
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { AddEditModalComponent } from './components/add-edit-modal/add-edit-modal.component';
import { CameraComponent } from './components/camera/camera.component';
import { DetectedUsersComponent } from './components/detected-users/detected-users.component';
// SERVICES
import { AzureAPIServices } from './services/azure';
//import { PulsesAPIServices } from './services/pulses';
import { ServicesApiChangesService } from './services';
import { ImageCropperService } from './services/assets/cropper';
import { MainComponent } from './vm/main/main.component';
import { appRoutes } from './app.routes';
import { FaceDetectModalComponent } from './components/face-detect-modal/face-detect-modal.component';
import { ImageAdsComponent } from './components/ads/image-ads/image-ads.component';
import { VideoAdsComponent } from './components/ads/video-ads/video-ads.component';
import { SmilesComponent } from './components/smiles/smiles.component';
import { AtmComponent } from './vm/atm/atm.component';
import { MetroComponent } from './vm/metro/metro.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ViolatersComponent } from './vm/violaters/violaters.component';
// Gauge
import { NgxGaugeModule } from 'ngx-gauge';
// MQTT
import {
  MqttModule,
  IMqttServiceOptions
} from 'ngx-mqtt';
import { CustomAPIServices } from './services/custom';
import { PulsesDemoServices } from './services/pulsesDemoService';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsModule } from 'ng2-charts';
import { SettingsComponent } from './vm/settings/settings.component';
import { getEnvironment } from './functions/environment';
import { HappinessChartComponent } from './components/happiness-chart/happiness-chart.component';

const environment:any = getEnvironment();

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.AppConfig.host,
  port: environment.AppConfig.port,
  path: '/ws',
  username: 'user',
  password: 'pass',
  protocol: 'wss',
  // ws://localhost:8083/mqtt
};

@NgModule({
  declarations: [
    AppComponent,
    VideoPlayerComponent,
    AddEditModalComponent,
    CameraComponent,
    DetectedUsersComponent,
    MainComponent,
    FaceDetectModalComponent,
    ImageAdsComponent,
    VideoAdsComponent,
    MetroComponent,
    SmilesComponent,
    AtmComponent,
    ViolatersComponent,
    SettingsComponent,
    HappinessChartComponent
  ],
  imports: [
    NgxChartsModule,
    ChartsModule,

    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogModule,
    CheckboxModule,
    InputSwitchModule,
    TooltipModule,
    DataViewModule,
    TableModule,
    ButtonModule,
    ScrollPanelModule,
    DropdownModule,
    RadioButtonModule,
    AccordionModule,
    WebcamModule,
    HttpClientModule,
    ImageCropperModule,
    ToastrModule.forRoot(),

    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    RouterModule.forRoot(
      appRoutes
    ),
    
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),

    NgxGaugeModule,
  ],
  providers: [
    ImageCropperService,
    ServicesApiChangesService, 
    AzureAPIServices, 
   /// PulsesAPIServices,
    CustomAPIServices,
    PulsesDemoServices,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
