import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'video-ads',
  templateUrl: './video-ads.component.html',
  styleUrls: ['./video-ads.component.scss']
})
export class VideoAdsComponent implements OnInit {
  @Input() adModalOpened;
  @Input() toggleCamera;
  @Input() videoURLs;
  @Input() videoURLIndex;
  @Output() dialogClose = new EventEmitter();
  constructor(){
    document.onkeydown = (evt:any) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this.adModalOpenedDismissed = true;
      }
    };
  }

  ngOnInit() {
  }
  
  adModalOpenedDismissed:boolean;
  _adModalOpenedDismissed(){
    this.adModalOpenedDismissed = false;
    this.adModalOpened = false;
  }
}
