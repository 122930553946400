import {Injectable,EventEmitter} from '@angular/core';
import {HttpClient,} from '@angular/common/http';
import {FaceModel} from '../../configs/models/faceModel';
import {Observable} from 'rxjs';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/delay';
import {map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import imageCompression from 'browser-image-compression';
import { detectedPerson } from '../../core/detectedPerson';
import { ImageCropperService } from '../assets/cropper';
import { deepCopy, getEmotion } from 'src/app/core/functions';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AzureAPIServices  implements IFaceAPIServices {

  singlePersonFaceLandmarks: any = [];
  singlePersonFaceRectangle: any = [];
  captureInterval: number;
  groupId = environment.GROUP_ID;
  allPersonGroupsId: any = [];
  singlePersonId: any;

  // Please DOO NOT edit the items below
  // AZURE API
  allGroupsAPIUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/`;
  personGroupAPIUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/${environment.GROUP_ID}`;
  createNewPersonAPIUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/${environment.GROUP_ID}/persons`;
  trainGroupAPIUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/${environment.GROUP_ID}/train`;
  trainingGroupStatusAPIUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/${environment.GROUP_ID}/training`;
  addPersonFaceAPIUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/${environment.GROUP_ID}/persons/`;
  personInfoUrl = `${environment.AppConfig.API}face/v1.0/largepersongroups/${environment.GROUP_ID}/persons/`;
  detectPersonFaceAPIUrl = `${environment.AppConfig.AZURE_API}face/v1.0/detect?returnFaceId=true&returnFaceLandmarks=false&returnFaceAttributes=age%2Cgender%2Csmile%2Cemotion`;
  identifyPersonAPIUrl = `${environment.AppConfig.AZURE_API}face/v1.0/identify`;

  watchDetectedPersons = new EventEmitter();
  constructor(
    private imageCropperService:ImageCropperService,
    private toastr: ToastrService, private http: HttpClient
  ){
  //  this.createPersonGroup().subscribe();
  }

  public createPersonGroup() {
    let data = {
      "name": this.groupId,
      "userData": "any"
    }
    return this.http.get(this.allGroupsAPIUrl).pipe(map(
      (res: any) => {
        let fIndex = res.findIndex(e => e.largePersonGroupId == this.groupId);
        if (fIndex == -1) {
          return this.http.put(this.personGroupAPIUrl, data).subscribe();
        }
      }
    ));
  }

  private updateAndCropImageAndCreate(_person, createPersonIfNotFound, notCreateUser?) {
    let person: any = {};
    person.age = _person.faceAttributes.age;
    person.gender = _person.faceAttributes.gender == 'male'?'man':'woman';
    person.DetectionDateTime = new Date();
    person.emotion = getEmotion(_person.faceAttributes.emotion);
    person.FaceImageBase64 = _person.FaceImageBase64;
    if (_person['_person'].candidates.length == 0 && createPersonIfNotFound) {
      imageCompression.getFilefromDataUrl(_person.FaceImageBase64, 'File.jpg').then((compressedFile) => {
        let images = [];
        images.push(compressedFile);
        let pendingPerson = {
          name: `unknown_${Math.random().toString(20).substring(11)}`,
          images,
          userData: '{}',
        }
        if(!notCreateUser){
          this.createNewPerson(pendingPerson).subscribe(personID => {
            person.name = pendingPerson.name;
            person.personID = personID;
            person.userData = pendingPerson.userData;
            person.newCreated = true;
            detectedPerson.next(person);
          });
        }else{
          person.newRegistered = true;
          person.name = '';
          person.userData = {
            balance: 10
          };
          person.userData = JSON.stringify(person.userData);
          detectedPerson.next(person);
        }
      });
    } else {
      this.http.get(`${this.personInfoUrl}${_person['_person'].candidates[0].personId}`).subscribe((resPerson: any) => {
        person.name = resPerson.name;
        person.personID = resPerson.personId;
        person.userData = resPerson.userData;
        if(person.name.indexOf('unknown_')!=-1){
          person.newCreated = true;
        }
        detectedPerson.next(person);
      });
    }
  }

  private listenIdentifiedPersons(persons, detectedPersonsValues, imageFile, createPersonIfNotFound, notCreateUser?){
    Observable
    .of(persons)
    .switchMap(x => x)
    .concatMap(item => Observable.of(item).delay(100))
    .subscribe((_person: any) => {
      let _detectedPerson:any = {};
      if(detectedPersonsValues){
        let _index = detectedPersonsValues.findIndex(f => f.faceId == _person.faceId);
        _detectedPerson = deepCopy(detectedPersonsValues[_index]);
      }else{
        _detectedPerson = _person;
      }
      _detectedPerson._person = _person;

      this.imageCropperService.crop(
        imageFile,
        _detectedPerson.faceRectangle.left,
        _detectedPerson.faceRectangle.top,
        _detectedPerson.faceRectangle.width,
        _detectedPerson.faceRectangle.height
      ).subscribe((_image: any) => {
        environment.saveCaptureImages?saveAs(imageFile, 'img.jpg'):'';
        imageCompression.getDataUrlFromFile(_image).then((compressedFile) => {
          _detectedPerson.FaceImageBase64 = compressedFile;
          this.updateAndCropImageAndCreate(_detectedPerson, createPersonIfNotFound, notCreateUser);
        });

      },()=>{
        environment.saveCaptureImages?saveAs(imageFile, 'img.jpg'):'';
        this.toastr.error("Cropping image API not working");
        imageCompression.getDataUrlFromFile(imageFile).then((compressedFile) => {
          _detectedPerson.FaceImageBase64 = compressedFile;
          this.updateAndCropImageAndCreate(_detectedPerson, createPersonIfNotFound, notCreateUser);
        });
      })
    });
  }

  public detectAndIdentifyPerson(imageFile, createPersonIfNotFound:boolean, userDetectedResponse?, notCreateUser?) {
    let faceIds: any = [];
    let data = {
      "confidenceThreshold": environment.AppConfig.confidenceThreshold,
      "ImageIsFaceOnly": environment.AppConfig.ImageIsFaceOnly,
      "SimilarityMargin": environment.AppConfig.SimilarityMargin,
      "AddPersonIfDoesntExist": environment.AppConfig.AddPersonIfDoesntExist,
      "ReturnDetectedFace": environment.AppConfig.ReturnDetectedFace,
      "DetectMask": environment.AppConfig.DetectMask,
      "faceIds": faceIds,
      "largePersonGroupId": this.groupId
    }
    return this.http.post <FaceModel[]> (this.detectPersonFaceAPIUrl, imageFile).pipe(map(
      (resDetect: any) => {
        resDetect.forEach(faceId => faceIds.push(faceId['faceId']));
        if (faceIds.length != 0) {
          if(!userDetectedResponse){
            return this.http.post(this.identifyPersonAPIUrl, data).subscribe((resIdentify: any) => {
              this.listenIdentifiedPersons(resIdentify, resDetect, imageFile, createPersonIfNotFound, notCreateUser);
            }, err => {
              if (err.error.error.code == 'LargePersonGroupNotTrained') {
                // this.listenIdentifiedPersons(resDetect, '', imageFile, createPersonIfNotFound);
                let countObservable = 0;
                Observable.of(resDetect)
                  .switchMap(x => x)
                  .concatMap(item => Observable.of(item).delay(800))
                  .subscribe((_person: any) => {
                    let emitData = JSON.parse(JSON.stringify(_person));
                    emitData._person = {
                      candidates: []
                    };
  
  
                    if (resDetect.length == countObservable) {
                      emitData.detectAndIdentifyPersonInprogress = true;
                    } else {
                      emitData.detectAndIdentifyPersonInprogress = false;
                    }
  
                    this.postImages(
                      `areawidth=${emitData.faceRectangle.width}&areaheight=${emitData.faceRectangle.height}&top=${emitData.faceRectangle.top}&left=${emitData.faceRectangle.left}`,
                      imageFile
                    ).subscribe((r: any) => {
  
                      imageCompression.getDataUrlFromFile(r)
                        .then((compressedFile) => {
                          emitData.FaceImageBase64 = compressedFile;
                          this.updateAndCropImageAndCreate(emitData, createPersonIfNotFound, notCreateUser);
                        })
                    });
                  })
              }
            });
          }else{
            return this.http.post(this.identifyPersonAPIUrl, data);
          }
        }
      }
    ));
  }

  public trainPersonGroup() {
    let data = {
      "largePersonGroupId": this.groupId
    }

    this.http.post(this.trainGroupAPIUrl, data).subscribe();
    return this.waitForTrainingCompletion();
  }

  public getGroupPersons() {
    return this.http.get(this.createNewPersonAPIUrl);
  }

  public createNewPerson(person) {
    let data = {
      "name": person.name,
      "userData": person.userData
    }
    return this.http.post(this.createNewPersonAPIUrl, data).pipe(map(
      (res: any) => {

        person.images.map(_img => {
          this.addPersonFace(res.personId, _img).subscribe(e => {
            this.trainPersonGroup();
          });
        });
        
        return res.personId;
      }
    ));
  }

  public editPerson(person) {
    let data = {
      "name": person.name,
      "userData": person.userData
    }
    return this.http.patch((this.createNewPersonAPIUrl + '/' + person.personID), data);
  }

  closeInterval;
  private waitForTrainingCompletion() {
    status =
      '';
    this.closeInterval = setInterval((e => {
      this.trainPersonGroupStatus().subscribe((r: any) => {
        status = r.status;
        r.status == 'running' ? '' : clearInterval(this.closeInterval);
      }, err => {
        clearInterval(this.closeInterval);
      });
    }), 500);

    return (status == 'notStarted' || status == 'succeeded');
  }

  private trainPersonGroupStatus() {
    return this.http.get(this.trainingGroupStatusAPIUrl)
  }

  public addPersonFace(singlePersonId, faceImage, trainGroup = false) {
    const formData = new FormData();
    formData.append('form', faceImage);
    formData.append('personId', singlePersonId);

    let addFaceUrl = this.addPersonFaceAPIUrl + singlePersonId + '/persistedfaces';
    return this.http.post(addFaceUrl, formData);
  }

  public getPersonFace(personId, persistedFaceId) {
    let addFaceUrl = this.addPersonFaceAPIUrl + personId + '/persistedfaces/' + persistedFaceId;
    return this.http.get(addFaceUrl);
  }

  getUserInfo(personId) {
    return this.http.get(`${this.personInfoUrl + personId}`);
  }

  public postImages(_params, data) {
    return this.http.post(`${environment.AppConfig.CropURL}extract?${_params}`, data, {
      responseType: 'blob'
    });
  }
  
}
