import { environment } from 'src/environments/environment';
export const getEnvironment = () => {
    let env:any = localStorage.getItem('environment')
    if(!env){
      localStorage.setItem('environment', JSON.stringify(environment));
      env = environment;
    }

    if(typeof env == 'string'){
        env = JSON.parse(env);
    }

    return env;
}

export const setEnvironment = (env) => {
    localStorage.setItem('environment', JSON.stringify(env));
}