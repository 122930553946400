import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Animations } from 'src/app/core/animations';
import { detectedPerson, detectedPersonAds } from 'src/app/core/detectedPerson';
import { deepCopy } from 'src/app/core/functions';
import * as moment from 'moment';
import * as _ from "lodash";
import { MqttService, IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { parseMqtt, getMqttParsedMessage } from 'src/app/core/functions/person';
import { VisitorCountsService } from 'src/app/functions/visitor-counts.service';
import { PulsesDemoServices } from 'src/app/services/pulsesDemoService';
import { Router } from '@angular/router';
import { getEnvironment } from 'src/app/functions/environment';

@Component({
  selector: 'app-detected-users',
  templateUrl: './detected-users.component.html',
  styleUrls: ['./detected-users.component.scss'],
  animations: [Animations.animeTrigger]
})
export class DetectedUsersComponent implements OnDestroy {
  environment: any;

  @Output() editUserOutput = new EventEmitter();
  @Input() hidedNewCreatedUsers: boolean;
  @Input() toggleCamera: boolean;

  showAge: any;
  showGender: any;
  showEmotions: any;
  showDetectionTime: any;

  detectedPersons: Array<any> = [];
  watchDetectedPersons = [];
  portrait: boolean;
  subscription: Subscription;
  mqttMessage: Subscription;

  constructor(
    private _mqttService: MqttService,
    private _visitorCountsService: VisitorCountsService,
    private _pulsesDemoServices: PulsesDemoServices,
    private _router: Router,
  ) {
    this.environment = getEnvironment();
    this.showAge = this.environment.AppConfig.showAge;
    this.showGender = this.environment.AppConfig.showGender;
    this.showEmotions = this.environment.AppConfig.showEmotions;
    this.showDetectionTime = this.environment.AppConfig.showDetectionTime;

    this.mqttMessage = getMqttParsedMessage.subscribe((parsedMessage: any) => {
      this.updateDetectedPersons(parsedMessage);
    });
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.portrait = true;
    } else {
      this.portrait = false;
    }

    if (this.environment.receiveFacesOnly) {
      // this.subscription = this._mqttService.observe('/pulses_demo/person').subscribe((message: IMqttMessage) => {
      //   let _message:any = message.payload.toString();
      //   parseMqtt(_message);
      // });

      this.subscription = detectedPerson.subscribe((message: IMqttMessage) => {
        let _message: any = message.payload.toString();
        parseMqtt(_message);
      });
    }

    setInterval(() => {
      let adsFilteredUsers: Array<any> = [];
      if (this.detectedPersons.length > 0) {
        adsFilteredUsers = deepCopy(this.detectedPersons);
        adsFilteredUsers = adsFilteredUsers.filter(_user => {
          return moment(_user.DetectionDateTime).add(this.environment.adsIntervalSecond, 'seconds') > moment();
        });
        if (adsFilteredUsers.length > 0) {
          this.detectSuitableAdForUser(adsFilteredUsers);
        }
      }
    }, (this.environment.adsIntervalSecond * 500));
  }

  editUser(user) {
    this.editUserOutput.emit(user);
  }
  removeFromCache(user): void {
    this._pulsesDemoServices.deleteFaceFromCache(user.faceId)
      .subscribe(() => console.log(`${user.faceId} removed from hash table.`),
        (e) => console.log(e)
      );
  }
  private _allVisitors = [];
  private _visibleVisitors = [];
  private checkExistInArr = (arr, value, obj?) => {
    if (arr.length == 0) {
      return false;
    }
    let index = arr.findIndex((e: any) => (obj ? e[obj] : e) == value)
    return index !== -1;
  }
  private findIndex = (arr, value, obj?) => {
    let index = arr.findIndex((e: any) => (obj ? e[obj] : e) == value)
    return index;
  }
  updateDetectedPersons(person) {
    if (person) {
      // Check user attributes
      if (person.userData) {
        if (!(typeof person.userData == 'object')) {
          person.userData = (typeof JSON.parse(person.userData) == 'object') ? JSON.parse(person.userData) : { Vip: false, attributes: [] };
        }
      } else {
        person.userData = {
          Vip: false,
          attributes: []
        };
      }
      if (!person.userData.attributes) { person.userData.attributes = [] }


      const mergeGrouping = () => {
        //debugger;
        //Call Merge: grouping(_allVisitors);
        this._visitorCountsService._groupUser(this._allVisitors).subscribe((groupedUsers) => {
          //Clear _VisibleVisitors

          this._visibleVisitors = [];
          //Add Only the latest face from each group
          groupedUsers['groupped'].forEach(_users => {
            if (_users.length == 0) { return }

            let groupedUser = [];
            let hasValidName = false;
            let hasValidNameIndex;
            _users.map((_user, i) => {
              let index = this.findIndex(this._allVisitors, _user, 'name');
              // if(index==-1){
              //   index = this.findIndex(this._allVisitors, _user, 'name');
              // }

              // Detect user has valid name
              if (!(this._allVisitors[index].name.match('unknown'))) {
                hasValidName = true;
                hasValidNameIndex = i;
              }

              groupedUser.push(this._allVisitors[index]);
            });

            if (!hasValidName) {
              groupedUser = _.orderBy(groupedUser, 'DetectionDateTime', ['desc']);
              this._visibleVisitors.push(groupedUser[0]);
            } else {
              this._visibleVisitors.push(groupedUser[hasValidNameIndex]);

              // Update each user name if there is valid name
              _users.map((_user, i) => {
                let index = this.findIndex(this._allVisitors, _user, 'name');

                const url = (this._router.url).split('/')[1].split('?')[0];
                let _person = JSON.parse(JSON.stringify(this._allVisitors[index]));
                _person.name = groupedUser[hasValidNameIndex].name;
                _person.userData = JSON.stringify(_person.userData);

                this._pulsesDemoServices.editPerson(_person, url).subscribe();
              })
            }
          });

          this.detectedPersons = _.orderBy(this._visibleVisitors, 'DetectionDateTime', ['desc']);
          // delete undetected persons
          let deleteIndex = [];
          groupedUsers['undetected'].forEach(user => {
            for (let i = 0, l = this.detectedPersons.length; i < l; i++) {
              if (this.detectedPersons[i].faceId === user) {
                deleteIndex.push(i);
              }
            }
          });
          for (let i = 0, l = deleteIndex.length; i < l; i++) {
            this.detectedPersons.splice(i, 1);
          }
          console.log(this.detectedPersons);

          //If the person is in it's own group (new) , increase the count by 1
          this._visitorCountsService._countUser(groupedUsers, this.detectedPersons);
        });
      }


      //Check if the person.id is in one of the _allVisitors;
      const { personId, name } = person;
      if (this.checkExistInArr(this._allVisitors, name, 'name')) {
        //person.id is there, check if they are in _visibleVisitors
        if (this.checkExistInArr(this._visibleVisitors, name, 'name')) {
          //person.id is there, just update the card (_visibleVisitors[personID]=person)
          let index = this.findIndex(this._visibleVisitors, name, 'name');
          // if(index==-1){
          //   index = this.findIndex(this._visibleVisitors, name, 'name');
          // }
          this._visibleVisitors[index] = person;
        } else {
          //_visibleVisitors.add(person)
          this._visibleVisitors.push(person)
        }
        this.detectedPersons = _.orderBy(this._visibleVisitors, 'DetectionDateTime', ['desc']);

        //UPDATE THE _allVisitors data as well (you already do it now)

        //IF ID IS UNKOWN --> Call Grouping for All Visitors again *
        //if(name.match('unknown')){
        if (!this.checkExistInArr(this._allVisitors, name, 'name')) mergeGrouping();
        // }
      } else {
        //_allVisitors.add(person)
        this._allVisitors.push(person);
        //Call Merge: grouping(_allVisitors);
        mergeGrouping();
      }





      // Filter by personId
      // if(person.personId){
      //   const foundPersonIndex = this.detectedPersons.findIndex((e:any)=>e.personId == person.personId);
      //   if(foundPersonIndex != -1){
      //     this.detectedPersons.splice(foundPersonIndex, 1);
      //   }
      // }

      // // Filter by name
      // const foundPersonByName = this.detectedPersons.findIndex((e:any)=>e.name == person.name);
      // if(foundPersonByName != -1){
      //   this.detectedPersons.splice(foundPersonByName, 1);
      // }

      // this.detectedPersons.unshift(person);
      // this._visitorCountsService.incrementVisitorsCount(person, this.detectedPersons);
      // this.groupUsers(this._visitorCountsService.groupedUsers);
    }
  };

  seniorMale = 0;
  adultMale = 0;
  seniorFemale = 0;
  adultFemale = 0;

  detectSuitableAdForUser(detectedPersons) {
    let ageFilter = [{
      value: 0, title: 'seniorMale'
    }, {
      value: 0, title: 'adultMale'
    }, {
      value: 0, title: 'seniorFemale'
    }, {
      value: 0, title: 'adultFemale'
    }]
    detectedPersons.map(_person => {
      if ((_person.age > 50) && (_person.gender == 'man')) {
        // Senior Male
        ageFilter[0].value++;
      } else if ((_person.age < 50) && (_person.gender == 'man')) {
        // Adult Male
        ageFilter[1].value++;
      } else if ((_person.age > 50) && (_person.gender == 'woman')) {
        // Senior Female
        ageFilter[2].value++;
      } else if ((_person.age < 50) && (_person.gender == 'woman')) {
        // Adult Female
        ageFilter[3].value++;
      } else {
        console.log("Error");
      }
    });

    setTimeout(() => {
      let nextAdVideo = ageFilter.sort((a, b) => b.value - a.value);
      detectedPersonAds.next(nextAdVideo[0].title + '0');
      this[nextAdVideo[0].title] == 1 ? this[nextAdVideo[0].title] = 0 : this[nextAdVideo[0].title] = 1;
    }, 100);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.mqttMessage.unsubscribe();
  }
}
