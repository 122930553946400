import { Component, OnChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { getEnvironment } from 'src/app/functions/environment';

@Component({
  selector: 'image-ads',
  templateUrl: './image-ads.component.html',
  styleUrls: ['./image-ads.component.scss']
})
export class ImageAdsComponent implements OnChanges, OnDestroy {
  environment:any;
  @Input("imgAdPersonTypeUrl") imgAdPersonTypeUrl;
  @Output() closeModal = new EventEmitter();
  imgAdsInterval;
  constructor(){
    this.environment = getEnvironment();
    this.imgAdsInterval = setInterval(()=>{
      this.playNextAd();
    }, this.environment.adsImageInterval);
    this.putAdsImage();
  }

  putAdsImage(){
    if(!this.imgAdPersonTypeUrl){return}
    this.imageAds = [];
    
    this.imageAds.push({
      url: `../../assets/ads/${this.imgAdPersonTypeUrl}1.jpg`
    },{
      url: `../../assets/ads/${this.imgAdPersonTypeUrl}0.jpg`
    });
  }
  playedAdIndex = 0;
  playNextAd(){
    if(this.imageAds.length==0){return}
    if(this.imageAds.length == this.playedAdIndex){this.playedAdIndex = 0}
    setTimeout(() => {
      document.getElementById("sliderAds").scrollLeft = this.playedAdIndex * 700;
      this.playedAdIndex++;
    });
  }
  ngOnChanges() {
    this.putAdsImage()
  }
  ngOnDestroy() {
    clearInterval(this.imgAdsInterval);
  }

  imageAds = [];

}
