import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { resizeImage } from 'src/app/core/functions';
import { take } from 'rxjs/operators';
import imageCompression from 'browser-image-compression';
import { ServicesApiChangesService } from 'src/app/services';
import { ToastrService } from 'ngx-toastr';
import { CameraComponent } from '../camera/camera.component';
import { getEnvironment } from 'src/app/functions/environment';

@Component({
  selector: 'face-detect-modal',
  templateUrl: './face-detect-modal.component.html',
  styleUrls: ['./face-detect-modal.component.scss']
})
export class FaceDetectModalComponent implements OnInit {
  environment;
  @ViewChild(CameraComponent) camera;
  @Output() modalClosed = new EventEmitter()
  constructor(
    public toastr: ToastrService,
    private servicesApiChangesService: ServicesApiChangesService
  ) {
    this.environment = getEnvironment();
  }

  videoCaptureInteval;
  reEnableInterval = false;
  startCapturing(){
    this.videoCaptureInteval = setInterval(() => {
      if(this.enableCamera){
        this.camera.genBase64Main().then( base64=>{
          // todo could be changed to below sameple(according migration to angular 10)
          //imageCompression(base64, {maxIteration: 1})
          imageCompression.getFilefromDataUrl(base64, 'File.jpg')
          .then((compressedFile)=>{
            const file = new File([compressedFile], "File.jpg", {type: 'image/png'});
            return this.detectAndIdentify(file, "checkEquality");
          });
        })
        .catch( e=>{this.enableCamera = true; console.error(e)} );
      }
    }, this.environment.AppConfig.captureInterval);
  }
  ngOnInit() {
    this.startCapturing()
  }

  selectedImage = "";
  isIdentified = false;
  enableCamera:boolean = false;
  handleFileSelect(evt){
    this.openResult();
    let file = evt.target.files[0];
    if (file){
      this.toastr.clear();
      resizeImage(file).pipe(take(1)).subscribe((base64:string)=>{
        this.selectedImage = base64;
        imageCompression.getFilefromDataUrl(base64, 'File.jpg')
        .then((compressedFile)=>{
          const file = new File([compressedFile], "File.jpg", {type: 'image/png'});
          return this.detectAndIdentify(file, 'enableCamera')
        });
      });
    }
  }

  uploadedImageId = "";
  capturedImageId = "";
  scanStarted = false;
  detectAndIdentify(file,obj?){
    if(obj == "enableCamera"){
      this.enableCamera = false;
      this.capturedImageId = "";
      this.scanStarted = true;
    }
    this.servicesApiChangesService.APIServices.detectAndIdentifyPerson(file, true, true).subscribe((_res)=>{
      if(!_res){return};
      _res.subscribe(detectedPerson=>{
        if(!detectedPerson || detectedPerson.length==0){
          this.selectedImage = "";
          this.scanStarted = false;
          this.toastr.error("No face detected")
        }
        if(detectedPerson.length == 1){
          let _id = "";
          if(detectedPerson[0].candidates.length == 0){
            this.toastr.error("No canditates found selected image");
            this.scanStarted = false;
            this.selectedImage = "";
            return false;
          }
          detectedPerson.map(_person=>{
            _id = _person.candidates[0].personId;
          });
          if(obj == "enableCamera"){
            this.uploadedImageId = _id;
            this.enableCamera = true;
            this.scanStarted = false;
            if(this.reEnableInterval){
              this.startCapturing();
            }
          }else{
            this.capturedImageId = _id;
          }
          if(this.capturedImageId == this.uploadedImageId){
            clearInterval(this.videoCaptureInteval);
            this.reEnableInterval = true;
          }
        }else{
          this.scanStarted = false;
          this.selectedImage = "";
          this.toastr.error("More than one face detected!");
        }
      });
    });
  }

  modalClose(){
    clearInterval(this.videoCaptureInteval);
    this.modalClosed.emit(true)
  }

  finalImageShow = false;
  cameraOpened = false;
  openResult(){
    this.finalImageShow = true;
  }

  goToMain(){
    this.cameraOpened = false;
    this.finalImageShow = false;
  }
}
