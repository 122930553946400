import { of, Subject } from 'rxjs';

export let getEmotion = (emotions:Array<any>)=>{
    let emotion = '';
    let _emotions = [];
    Object.keys(emotions).map(_k => {
        _emotions.push(emotions[_k]);
    });

    Object.keys(emotions)
        .map(_k => {
        if (emotions[_k] == Math.max.apply(null, _emotions)) {
            if (_k == 'anger' || _k == 'disgust' || _k == 'fear' || _k == 'sadness') {
                emotion = 'unhappy';
            } else if (_k == 'happiness' || _k == 'contempt' || _k == 'surprise') {
                emotion = 'happy';
            } else {
                emotion = 'neutral';
            }
        }
    });
    return emotion;
}

export let base64ToBlob = (base64:string)=>{
    return fetch(base64).then(res => res.blob());
}

export let resizeImage = (file) => {
    let _resizeImage = new Subject();
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var maxW = 400;
    var maxH = 400;
    var img = document.createElement('img');

    img.onload = function() {
      var iw = img.width;
      var ih = img.height;
      var scale = Math.min((maxW / iw), (maxH / ih));
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      context.drawImage(img, 0, 0, iwScaled, ihScaled);
      _resizeImage.next(canvas.toDataURL())
    }
    img.src = URL.createObjectURL(file);
    return _resizeImage;
}

export let deepCopy = (_json)=>{
    return JSON.parse(JSON.stringify(_json));
}