import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WebcamComponent, WebcamImage } from 'ngx-webcam';
import { ToastrService } from 'ngx-toastr';
import { ServicesApiChangesService } from 'src/app/services';
import { base64ToBlob, resizeImage, deepCopy } from 'src/app/core/functions';
import { take } from 'rxjs/operators';
import { PulsesDemoServices } from 'src/app/services/pulsesDemoService';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'add-edit-modal',
  templateUrl: './add-edit-modal.component.html',
  styleUrls: ['./add-edit-modal.component.scss']
})
export class AddEditModalComponent implements OnInit {
  @Input() editPerson;
  @Input() editPersonId;
  @Input() user;
  @Input() userCore;
  @Output() modalClosed = new EventEmitter();
  @Output() dialogClose = new EventEmitter();
  cameraViewOpen;

  public deviceId: string;
  private base64: string;
  imageBase64: Subject<string> = new Subject<string>();
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  selectOptionOpen:boolean;
  ___imageUploads = [];
  _imageUploads = [
    'https://beta-app.pulses.ai/assets/imgs/visitors-management/0.png', 
    'https://beta-app.pulses.ai/assets/imgs/visitors-management/1.png', 
    'https://beta-app.pulses.ai/assets/imgs/visitors-management/2.png'
  ];
  _selectedImgIndex = -1;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    // private servicesApiChangesService: ServicesApiChangesService,
    private pulsesDemoServices: PulsesDemoServices,
  ){}

  ngOnInit() {
  }

  selectImage(_selectedImgIndex) {
    this.selectOptionOpen = true;
    this._selectedImgIndex = _selectedImgIndex;
  }

  genBase64(){
    this.genBase64Main()
    .then((base64: string) => {
      base64ToBlob(base64)
      .then(blob => {
        setTimeout(() => {
          this._imageUploads[this._selectedImgIndex] = base64;
          this.selectOptionOpen = false;
          this.base64 = base64;
          const file = new File([blob], "File.jpg", {type: 'image/png'});
          this.___imageUploads[this._selectedImgIndex] = file;
          this._selectedImgIndex = -1;
        }, 100);
      })
    })
    .catch( e=>console.error(e) )
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.imageBase64.next(webcamImage.imageAsBase64);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public genBase64Main(): Promise<string> {
    return new Promise(resolve => {
      this.triggerSnapshot();
      this.imageBase64.subscribe((imageUrl: string) => {
        resolve(imageUrl);
      });
    });
  }

  handleFileSelect(evt){
    let file = evt.target.files[0];
    this.___imageUploads[this._selectedImgIndex] = file;
    if (file){
      resizeImage(file).pipe(take(1)).subscribe((base64:string)=>{
        this._handleReaderLoaded(base64);
      });
    }
  }
  _handleReaderLoaded(readerEvt) {
    this._imageUploads[this._selectedImgIndex] = readerEvt; 
    this.selectOptionOpen = false;
  }

  multiplyAttributes(arr:Array<any>){
    this.user.userData.attributes = [
      ...arr,
      ...[{
        "key": "",
        "value": ""
      }]
    ];
  }

  createUser(){
    let _user = deepCopy(this.user);
    _user.images = this.___imageUploads;
    _user.userData = JSON.stringify(_user.userData);
    const closeAndResetModal = (value) => {
      this.toastr.success(value?"Person is created!":"Person is edited!");
      this.user = {
        name: '',
        userData: {
          Vip: false,
          attributes: []
        }
      }
      this.editPerson = false;
      this.modalClosed.emit(true);
    }

    if(!this.editPerson){
      this.pulsesDemoServices.createNewPerson(_user)
      .subscribe(()=>closeAndResetModal(true), err=>{
        this.toastr.error(err.message);
      });
    }else{
      const url = (this.router.url).split('/')[1].split('?')[0];
      this.pulsesDemoServices.editPerson(_user, url).subscribe(res=>closeAndResetModal(false), err=>{
        this.toastr.error(err.message);
      });
    }
  }

  deleteRow(arr:Array<any>, index){
    arr.splice(index, 1);
  }

}
