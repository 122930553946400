import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';

@Component({
  selector: 'video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent {

  constructor() { }

  api: VgApiService;
  @Input() videoURLIndex = 0;
  @Input() videoURLs = [];
  @Output() adModalOpenedDismissed = new EventEmitter();
  videoURL = '';
  playedIndex = 0;
  onPlayerReady(api: VgApiService) {
    this.videoURL = this.videoURLs[this.playedIndex];
    (this.playedIndex == 0) ? this.playedIndex = 1 : this.playedIndex = 0;
    this.api = api;
    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      () => {
        // Set the video to the beginning
        this.api.getDefaultMedia().currentTime = 0;
      }
    );

    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      ($event) => {
        if (this.videoURLs.length != 0) {
          this.videoURL = this.videoURLs[this.playedIndex];
          (this.playedIndex == 0) ? this.playedIndex = 1 : this.playedIndex = 0;
          setTimeout(() => {
            this.api.play();
          }, 100);
        } else {
          this.adModalOpenedDismissed.emit(true)
        }
      }
    );
  }
}
