import { Routes } from '@angular/router';
import { MainComponent } from "./vm/main/main.component";
import { SettingsComponent } from './vm/settings/settings.component';

export const appRoutes: Routes = [
    { path: '', component: MainComponent },
    { path: 'metro', component: MainComponent },
    { path: 'metro/rta', component: MainComponent },
    { path: 'metro/violations', component: MainComponent },
    { path: 'metro/transactions', component: MainComponent },
    { path: 'metro/adib', component: MainComponent },
    { path: 'atm', component: MainComponent },
    { path: 'azure', component: MainComponent },
    { path: 'pulses', component: MainComponent },
    { path: 'settings', component: SettingsComponent },
];