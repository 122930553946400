import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-smiles',
  templateUrl: './smiles.component.html',
  styleUrls: ['./smiles.component.less']
})
export class SmilesComponent implements OnInit, OnChanges {
  @Input('opacity') opacity;
  @Input('logo') logo;
  @Input('metroPerson') metroPerson;
  smileTop:boolean;
  welcomeText:boolean = true;
  cloaseLoader:boolean;
  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      if(this.logo){
        this.smileTop = true;
      }
    }, 200);

    setTimeout(() => {
      if(this.logo){
        this.welcomeText = false;
      }
    }, 1500);
  }

  ngOnChanges(){
    if(this.metroPerson){
      setTimeout(() => {
        this.cloaseLoader = true;
      }, 200);
    }
  }

}
