import { Component, AfterContentInit, ViewChild, OnInit, OnDestroy } from '@angular/core';
// ---- DEPENDENDS 
import 'rxjs/add/observable/interval';
import imageCompression from 'browser-image-compression';
// ---- COMPONENTS
import { CameraComponent } from '../../components/camera/camera.component';
import { DetectedUsersComponent } from '../../components/detected-users/detected-users.component';
// ---- SERVICES
import { ServicesApiChangesService } from '../../services';
// ---- CONFIGS
import { detectedPerson, detectedPersonAds } from '../../core/detectedPerson';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { PulsesDemoServices } from 'src/app/services/pulsesDemoService';


import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { VisitorCountsService } from 'src/app/functions/visitor-counts.service';
import { getEnvironment } from 'src/app/functions/environment';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterContentInit, OnInit, OnDestroy{
  @ViewChild(CameraComponent) cameraComponent;
  @ViewChild(DetectedUsersComponent) detectedUsersComponent;
  environment:any;
  env:any;
  partnerLogoURL:any;
  display: boolean = false;
  imgAdPersonTypeUrl = "";
  videoURLs = [];
  videoURLIndex = 0;
  workingTime:boolean = true;
  portrait:boolean = false;
  splitHeight;
  splitHeightBottom;
  isATM:boolean;
  pinCode = [
    {value: ''},
    {value: ''},
    {value: ''},
    {value: ''},
  ];
  atmPerson;
  userDetectedATMButtons:boolean;
  isMetro: boolean = false;
  defaultCustom: boolean = true;
  metroPerson;
  metroDetectedUsers = [];
  metroCurrency:any;
  waitRegistration:boolean;
  isViolaters:boolean;

  showChart:any;
  constructor (
    private toaster: ToastrService, 
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private servicesApiChangesService: ServicesApiChangesService,
    private pulsesDemoServices: PulsesDemoServices,
    private visitorCountsService: VisitorCountsService,
  ){

    this.environment = getEnvironment();

    this.env = this.environment;
    this.partnerLogoURL = this.environment.partnerLogoURL;
    this.metroCurrency = this.environment.metroCurrency;
    this.showChart = this.environment.AppConfig.showChart;

    this.splitHeight = window.innerHeight/3 + 'px';
    this.splitHeightBottom = (window.innerHeight - window.innerHeight/3)/3 + 'px';
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.portrait = true;
    }else{
      this.portrait = false;
    }

    setInterval(() => {
      var Utc = moment.utc(new Date).toDate();
      const hour = Number(moment(Utc).local().format('HH'));
      if((hour>=this.environment.workingTime.starts)&&(hour<this.environment.workingTime.ends)){
        this.workingTime = true;
      }else{
        this.workingTime = false;
      }
    }, 1000);
    this.activatedRoute.queryParams.subscribe(_query=>{
      if((this.router.url).split('/')[1].split('?')[0]=='azure'){
        this.isViolaters = false;
        this.azureAPIEnabled = true;
        this.customAPIEnabled = false;
        // this.servicesApiChangesService.changeServer('azure');
        this.defaultCustom = true;
        this.userDetectedATM = false;
        this.isMetro = false;
      }else if((this.router.url).split('/')[1].split('?')[0]=='pulses'){
        this.isViolaters = false;
        this.azureAPIEnabled = false;
        this.customAPIEnabled = true;
        // this.servicesApiChangesService.changeServer('pulses');
        this.defaultCustom = true;
        this.userDetectedATM = false;
        this.isMetro = false;
      }else if((this.router.url).split('/')[1].split('?')[0]=='atm'){
        this.isViolaters = false;
        this.isATM = true;
        this.defaultCustom = false;
        this.userDetectedATM = false;
        detectedPerson.subscribe((person)=>{
          if(!person){return};
          this.atmPerson = person;
          clearInterval(this._videoCaptureInteval);
          setTimeout(() => {
            this.userDetectedATMButtons = true;
          }, 1500);
        });
      }else if((this.router.url).split('/')[1].split('?')[0]=='metro'){
        if((this.router.url).split('/').slice(-1)[0]=='violations'
        || (this.router.url).split('/').slice(-1)[0]=='transactions'
        ){
          this.isViolaters = true;
          this.isMetro = false;
          this.defaultCustom = false;
          this.userDetectedATM = false;
        }else{
          this.isViolaters = false;
          this.isMetro = true;
          this.defaultCustom = false;
          this.userDetectedATM = false;
        }
      }
    });
    detectedPersonAds.subscribe((_videoName:string)=>{
      if(_videoName){
        let index = _videoName.split('.mp4')[0].slice(-1);
        let videoName = _videoName.split('0')[0].split('/').slice(-1)[0];
        this.imgAdPersonTypeUrl = `${videoName}`;
        if(Number(index)==0){
          this.videoURLs[0] = `../../assets/ads/${videoName}1.mp4`;
          this.videoURLs[1] = `../../assets/ads/${videoName}0.mp4`;
        }else{
          this.videoURLs[0] = `../../assets/ads/${videoName}0.mp4`;
          this.videoURLs[1] = `../../assets/ads/${videoName}1.mp4`;
        }
        this.videoURLIndex = Number(index);
      }
    });
  }

  resetDialogValues(displayValue){
    this.user = {
      name: '',
      userData: {
        Vip: false,
        attributes: []
      }
    }
    this.display = displayValue;
    this.editPersonId = '';
    this.editPerson = false;
  }

  showDialog() {this.resetDialogValues(true)}

  modalClosed(){this.resetDialogValues(false)}
  enableVideoCaptureOver:boolean;
  modalClosedOver(){
    this.display = false;
    this.enableVideoCaptureOver = false;
  }
  modalIdCheck(){
    this.display = true;
    this.enableVideoCaptureOver = true;
  }

  enableVideoCapture = false;
  videoCaptureInteval;
  pendings:Array<any> = []
  toggleInterVal(enableVideoCapture){
    this.enableVideoCapture = enableVideoCapture;
    if(this.enableVideoCapture){
      this.videoCaptureInteval = setInterval(() => {
        if(this.workingTime){
          this.cameraComponent.genBase64Main().then( base=>{
            // original code imageCompression.getFilefromDataUrl(base, {maxIteration: 1,})
            imageCompression.getFilefromDataUrl(base, 'File.jpg')
            .then((compressedFile)=>{
              const url = (this.router.url).split('/')[1].split('?')[0];
              this.pendings.unshift(new Date());
              const file = new File([compressedFile], "File.jpg", {type: 'image/png'});
              this.pulsesDemoServices.detectAndIdentifyPerson(file, true, false, url);
              // return this.servicesApiChangesService.APIServices.detectAndIdentifyPerson(file, true).subscribe();
            });
          })
          .catch( e=>console.error(e) );
        }
      }, this.environment.AppConfig.captureInterval);
    }else{
      clearInterval(this.videoCaptureInteval);
    }
  }

  reloadTable(){
    const detectedUsers = JSON.parse(localStorage.getItem('detectedUsers')) || [];
    let dataSeries = [];

    let data = [];
    let labels = [];
    if(detectedUsers.length==0){return false;}
    detectedUsers.map(dUser=>{
      dataSeries.push({
        name: moment(dUser.time).format("h:mm a"),
        value: dUser.detectedUsersCount,
      });

      data.push(Number(dUser.detectedUsersCount));

      labels.push(moment(dUser.time).format("h:mm a"));


    });
    this.multi = [{
      name: 'User Visits',
      series: dataSeries,
    }];

    this.lineChartData[0].data = data;

    this.lineChartLabels = labels;
    // public lineChartData = [
    //   { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    // ];
    // public lineChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  }
  reloadTableInterval;
  ngAfterContentInit() {
    if(this.defaultCustom){
      this.toggleInterVal(true);
    }

    this.reloadTable();
    // this.reloadTableInterval = setInterval(()=>{
    //   this.reloadTable();
    // }, 1000);

    this.visitorCountsService.dataTableUpdates$.subscribe(res=>{
      this.reloadTable();
    });
  }

  ngOnDestroy(){
    clearInterval(this.reloadTableInterval);
  }

  editPerson = false;
  editPersonId;
  user:any = {
    name: '',
    userData: {
      Vip: false,
      attributes: []
    }
  }
  userCore;
  editUser(person){
    this.userCore = '';
    this.user = {};
    setTimeout(() => {
      this.display = true;
      this.userCore = JSON.parse(JSON.stringify(person));
      this.editPersonId = person.personID;
      this.editPerson = true;
      this.user = person;
      this.user.userData = person.userData;
      if(!this.user.userData.attributes){
        this.user.userData.attributes = []
      }
    }, 100);
  }

  adModalOpened = false;
  openAdModal(){
    this.adModalOpened = true;
    this.showDialog();
  }

  dialogClose(){
    setTimeout(() => {
      this.display = false;
      this.adModalOpened = false;
      if(this.userCore&&this.editPersonId){
        detectedPerson.next(this.userCore);
      }
    }, 100);
  }

  hidedNewCreatedUsers:boolean = false;
  _watchDetectedPersons = [];
  hideNewCreatedUsers(){
    this.hidedNewCreatedUsers = !this.hidedNewCreatedUsers;
  }

  azureAPIEnabled:boolean = true;
  customAPIEnabled:boolean = false;
  changeService(val){
    if(val == 'customAPIEnabled'){
      this.azureAPIEnabled = !this.azureAPIEnabled;
      this.servicesApiChangesService.changeServer('pulses');
    }else{
      this.customAPIEnabled = !this.azureAPIEnabled;
      this.servicesApiChangesService.changeServer('azure');
    }
  }

  showImageAd:boolean = false;
  showImageAdFn(){
    this.showImageAd = !this.showImageAd;
  }

  closeModalImgAds(){
    this.showImageAd = false;
  }


  // ***********************************
  // ATM
  // ***********************************

  detectFaceATM:boolean = false;
  paymentActive;
  pinValue;
  _pinValue = '1234';
  _pinChangeCheck(value){
    console.log(value)
    if((value+'').length==4){
      if(value==this._pinValue){
        const _metroPerson = JSON.parse(JSON.stringify(this.metroPerson));
        _metroPerson.userData = JSON.stringify(_metroPerson.userData);
        this.pulsesDemoServices.createNewPerson(_metroPerson).subscribe(res=>{
          setTimeout(() => {
            this.metroConfirm = false;
            this.metroPerson.newRegistered = false;
            this.waitRegistration = false;
            this.toaster.clear();
            this.toaster.success("Succed!");
          }, 100);
        })   
      }else{
        this.toaster.error("PIN is incorrect");
      }
    }
  }
  pinChangeCheck(event, i){
    if(isNaN(event)||!event){
      return;
    }
    let targetElm = document.getElementById(`pin${i+1}`);
    if(targetElm){
      targetElm.focus();
    }
    let pinNum = '';
    this.pinCode.map(res=>{
      pinNum = pinNum + res.value;
    });

    setTimeout(() => {
      if((pinNum+'') == this._pinValue){
        if(this.isMetro){
          const _metroPerson = JSON.parse(JSON.stringify(this.metroPerson));
          _metroPerson.userData = JSON.stringify(_metroPerson.userData);
          this.pulsesDemoServices.createNewPerson(_metroPerson).subscribe(res=>{
            setTimeout(() => {
              this.metroConfirm = false;
              this.metroPerson.newRegistered = false;            
              this.paymentActive = true;
              this.waitRegistration = false;
              this.toaster.clear();
              this.toaster.success("Succed!");
            }, 100);
          })
        }else{
          this.detectFaceATM = true;
        }
      }else{
        if((pinNum+'').length==this._pinValue.length){
          this.toaster.error("PIN is incorrect")
        }
      }

    }, 100);
  }


  _videoCaptureInteval;
  reEnableInterval = false;
  startCapturing(){
    this._videoCaptureInteval = setInterval(() => {
      if(this.detectFaceATM){
        this.cameraComponent.genBase64Main().then( base64=>{
          // original code imageCompression.getFilefromDataUrl(base64, {maxIteration: 1,})
          imageCompression.getFilefromDataUrl(base64, 'File.jpg')
          .then((compressedFile)=>{
            const file = new File([compressedFile], "File.jpg", {type: 'image/png'});
            return this.detectAndIdentify(file);
          });
        })
        .catch( e=>{this.detectFaceATM = true; this.isMetro = true; console.error(e)} );
      }
    }, this.environment.AppConfig.captureInterval);
  }

  userDetectedATM:boolean;
  detectAndIdentify(file){
    this.pulsesDemoServices.detectAndIdentifyPerson(file, false, false);
  }


  // ***********************************
  // Metro
  // ***********************************

  metroConfirm:boolean;
  openMetroConfirmModal(){

  }

  editMetroPerson(person){
    const url = (this.router.url).split('/')[1].split('?')[0];
    let _person = JSON.parse(JSON.stringify(person));
    _person.userData = JSON.stringify(_person.userData);
    this.pulsesDemoServices.editPerson(_person, url).subscribe();
  }

  topUp:false;
  chargeMetro(metroPerson, balance){
    if(typeof this.metroPerson.userData == 'string'){
      this.metroPerson.userData = JSON.parse(this.metroPerson.userData);  
    }
    this.metroPerson.userData.balance = this.metroPerson.userData.balance + balance;
    this.topUp = false;
    this.editMetroPerson(metroPerson)
  }

  // pinResendTime = moment().format('mm:ss');
  duration:any = '4:30';
  pinResentTime;
  pinResentTimeFn(){
    this.pinResentTime = moment().add(5, 'minutes');
    let counter;
    this.pinResendTimeInterval = setInterval(()=>{
      counter = 1;
      let time:any = this.duration.split(':');
      time[1] = time[1] - counter;
      if(time[1]==0){
        if(time[0]==0){
          clearInterval(this.pinResendTimeInterval);
        }else{
          time[0] = time[0] - counter;
        }
      }
      this.duration = '';
      this.duration = this.duration + time[0] + ':';
      this.duration = this.duration + time[1] + '';
    }, 1000)
  }
  pinResendTimeInterval;
  ngOnInit() {
    this.startCapturing();
  }





  view: any[] = [470, 300];

  // options
  colorScheme = {domain: ['#e00']};
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Time';
  showYAxisLabel = true;
  yAxisLabel = 'Number of visitors';
  timeline = true;

  multi: any[] = [];















  public lineChartData = [
    { data: [], label: 'Visitors Count by Time' },
  ];
  public lineChartLabels = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        gridLines: { color: 'rgba(255,255,255,0.1)' },
        ticks: {
          beginAtZero: true,
          userCallback: function(label, index, labels) {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label;
            }
          },
        }
      }],
      xAxes: [{
        gridLines: { color: 'rgba(255,255,255,0.1)' }
      }],
    },
  };
  public lineChartColors = [
    { // grey
      backgroundColor: 'rgba(51, 163, 214, 0.1)',
      borderColor: '#2ba6de',
      pointBackgroundColor: '#2ba6de',
      pointBorderColor: '#2ba6de',
      pointHoverBackgroundColor: '#2ba6de',
      pointHoverBorderColor: 'rgba(51, 163, 214, 0.78)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';

}
